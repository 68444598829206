const config = [
  {
    key: 'zh-Hans',
    value: '简体中文',
    languageTeam: 'Chinese Simplified',
    language: 'zh_CN',
    path: '/static/locale/zh-Hans/mdTranslation.js',
  },
  {
    key: 'en',
    value: 'English',
    languageTeam: 'English',
    language: 'en_US',
    path: '/static/locale/en/mdTranslation.js',
  },
];

export default config;
