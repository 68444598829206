
export const ROUTE_CONFIG = [
  {
    path: '/',
    component: () => import('./pages/CheckEnvironment'),
    title: () => window._l('环境检测'),
    exact: true,
  }, {
    path: '/setUrl',
    component: () => import('./pages/Seturl'),
    title: () => window._l('访问地址设置'),
  }, {
    path: '/init',
    component: () => import('./pages/Init'),
    title: () => window._l('系统初始化'),
  }, {
    path: '/secretKey',
    component: () => import('./pages/SecretKey'),
    title: () => window._l('请输入 SecretKey'),
  }, {
    path: '/settings',
    component: () => import('./pages/NewSettings'),
    title: () => window._l('管理后台'),
  }
];

// {
//   path: '/settings',
//   component: () => import('./pages/Settings'),
//   title: window._l('更多设置'),
// }, {
//   path: '/installMode',
//   component: () => import('./pages/InstallMode'),
//   title: window._l('安装方式选择'),
// }, {
//   path: '/alreadyInit',
//   component: () => import('./pages/AlreadyInit'),
//   title: window._l('系统初始化'),
// }

export const withoutHeader = [ ];