import React from 'react';
import logo from '../images/logo.png';
import { getLocalStorage } from '../common/utils';
import { Dropdown, Space, Menu } from 'antd';
import { DownOutlined, TranslationOutlined } from '@ant-design/icons';
import langConfig from '../common/langConfig';
import _ from 'lodash';
import './index.scss';

function Header(props) {
  const defaultLang = navigator.language === 'zh-CN' ? 'zh-Hans' : 'en';
  const lang = window.getCookie('i18n_langtag') || defaultLang;
  return (
    <div className="privateDeploymentHeader">
      <div className="logoWrap">
        <div><img src={logo} alt="logo" /></div>
        <div className="text">{window._l('HAP 私有部署版')}</div>
      </div>
      <Dropdown
        trigger="click"
        overlay={(
          <Menu>
            {langConfig.map(data => (
              <Menu.Item
                key={data.key}
                onClick={() => {
                  window.setCookie('i18n_langtag', data.key);
                  window.location.reload();
                }}
              >
                {data.value}
              </Menu.Item>
            ))}
          </Menu>
        )}
      >
        <Space className="pointer">
          <TranslationOutlined />
          {_.find(langConfig, { key: lang }).value}
          <DownOutlined />
        </Space>
      </Dropdown>
    </div>
  );
}

export default Header;
