import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Result } from 'antd';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import DocumentTitle from 'react-document-title';
import Loadable from 'react-loadable';
import Header from './Header';
import { ROUTE_CONFIG, withoutHeader } from './routeConfig';
import * as serviceWorker from './serviceWorker';
import { initLang } from './common/utils.js';
import './index.scss';

initLang();

const getComponent = component => Loadable({
  loader: component,
  loading: () => null,
});

const renderComponentWithTitle = (props, item) => {
  const { title, component } = item;
  const Comp = getComponent(component);
  return (
    <Fragment>
      <DocumentTitle title={title()} />
      <Comp {...props} />
    </Fragment>
  );
}

const Wallet = () => {
  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
    />
  );
}

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path={`/(${withoutHeader.join('|')})`} component={null} />
      <Route path="/" component={Header} />
    </Switch>
    <Switch>
      {
        ROUTE_CONFIG.map((item, index) => (
          <Route key={index} path={item.path} component={props => renderComponentWithTitle(props, item) } exact={item.exact} />
        ))
      }
      <Route component={Wallet} exact />
    </Switch>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
